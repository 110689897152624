import React, { useState, useEffect, useCallback } from 'react';
import { 
  GitBranch, 
  RefreshCw, 
  Filter, 
  Search, 
  AlertTriangle, 
  Check, 
  X, 
  Eye, 
  Clock, 
  Play, 
  Square,
  XSquare,
  CheckCircle,
  XCircle,
  PauseCircle,
  Loader,
  ChevronDown,
  ChevronRight,
  Calendar,
  BarChart2,
  Clock8,
  RotateCcw,
  ArrowRightLeft,
  AlertCircle,
  Send,
  FileText,
  ChevronsLeft,
  ChevronsRight,
  ChevronLeft,
  ChevronUp
} from 'lucide-react';

import Card from '../../components/shared/Card';
import Button from '../../components/shared/Button';
import StatusBadge from '../../components/shared/StatusBadge';
import DateFormatter from '../../components/shared/DateFormatter';
import WorkflowStateTransitionDiagram from '../../components/shared/WorkflowStateTransitionDiagram';
import apiService from '../../services/api.service';

/**
 * Composant pour le suivi des instances de workflow CEMAC-ACH en cours
 */
const WorkflowInstancesMonitor = () => {
  // États pour les instances de workflow
  const [workflowInstances, setWorkflowInstances] = useState([]);
  const [filteredInstances, setFilteredInstances] = useState([]);
  const [selectedInstance, setSelectedInstance] = useState(null);
  const [expandedInstanceId, setExpandedInstanceId] = useState(null);
  const [instanceHistory, setInstanceHistory] = useState([]);
  const [availableTransitions, setAvailableTransitions] = useState([]);
  
  // États pour les workflows disponibles (définitions)
  const [workflowDefinitions, setWorkflowDefinitions] = useState([]);
  const [selectedDefinition, setSelectedDefinition] = useState(null);
  
  // États pour les filtres
  const [searchTerm, setSearchTerm] = useState('');
  const [statusFilter, setStatusFilter] = useState('all');
  const [typeFilter, setTypeFilter] = useState('all');
  const [dateFilter, setDateFilter] = useState({
    start: null,
    end: null
  });
  const [showFilters, setShowFilters] = useState(false);
  
  // États pour la pagination
  const [pagination, setPagination] = useState({
    currentPage: 0,
    pageSize: 10,
    totalItems: 0,
    totalPages: 0
  });
  const [isLocalFiltering, setIsLocalFiltering] = useState(false);
  
  // États UI
  const [loading, setLoading] = useState(true);
  const [actionLoading, setActionLoading] = useState(false);
  const [historyLoading, setHistoryLoading] = useState(false);
  const [error, setError] = useState(null);
  const [successMessage, setSuccessMessage] = useState(null);
  const [showWorkflowDiagram, setShowWorkflowDiagram] = useState(false);
  const [autoRefresh, setAutoRefresh] = useState(false);
  const [refreshInterval, setRefreshInterval] = useState(30); // secondes
  const [timeLeft, setTimeLeft] = useState(refreshInterval);
  const [showDetailsModal, setShowDetailsModal] = useState(false);
  const [statsVisible, setStatsVisible] = useState(true);
  const [workflowStats, setWorkflowStats] = useState({
    total: 0,
    active: 0,
    completed: 0,
    failed: 0,
    byType: {}
  });
  
  // Initialisation
  useEffect(() => {
    Promise.all([
      fetchWorkflowInstances(),
      fetchWorkflowDefinitions()
    ]).then(() => setLoading(false))
      .catch(err => {
        console.error("Erreur lors du chargement des données:", err);
        setError("Une erreur s'est produite lors du chargement des données. Veuillez réessayer.");
        setLoading(false);
      });
  }, []);
  
  // Mise à jour des statistiques lorsque les instances changent
  useEffect(() => {
    if (workflowInstances.length > 0) {
      updateWorkflowStats(workflowInstances);
    }
  }, [workflowInstances]);
  
  // Auto-rafraîchissement
  useEffect(() => {
    let intervalId;
    
    if (autoRefresh) {
      setTimeLeft(refreshInterval);
      
      // Décompte du temps restant
      const countdownId = setInterval(() => {
        setTimeLeft(prev => {
          if (prev <= 1) {
            return refreshInterval;
          }
          return prev - 1;
        });
      }, 1000);
      
      // Rafraîchissement périodique
      intervalId = setInterval(() => {
        fetchWorkflowInstances();
      }, refreshInterval * 1000);
      
      return () => {
        clearInterval(intervalId);
        clearInterval(countdownId);
      };
    }
    
    return () => {
      if (intervalId) clearInterval(intervalId);
    };
  }, [autoRefresh, refreshInterval]);
  
  // Effet pour déclencher la récupération des données lors de la navigation
  useEffect(() => {
    if (!isLocalFiltering) {
      fetchWorkflowInstances();
    }
  }, [pagination.currentPage, pagination.pageSize, isLocalFiltering]);
  
  // Filtrage des instances (mode local)
  useEffect(() => {
    if (!workflowInstances.length) return;
    
    // Déterminer si on filtre localement ou si on demande de nouvelles données du serveur
    const shouldFilterLocally = 
      searchTerm.trim() !== '' || 
      dateFilter.start || 
      dateFilter.end;
    
    setIsLocalFiltering(shouldFilterLocally);
    
    if (shouldFilterLocally) {
      let filtered = [...workflowInstances];
      
      // Recherche textuelle
      if (searchTerm.trim() !== '') {
        const searchLower = searchTerm.toLowerCase();
        filtered = filtered.filter(instance => 
          instance.reference?.toLowerCase().includes(searchLower) ||
          instance.currentState?.toLowerCase().includes(searchLower) ||
          instance.workflowCode?.toLowerCase().includes(searchLower) ||
          (instance.context && 
          typeof instance.context === 'object' && 
          Object.values(instance.context).some(val => 
            val && typeof val === 'string' && val.toLowerCase().includes(searchLower)
          ))
        );
      }
      
      // Filtre par date
      if (dateFilter.start) {
        const startDate = new Date(dateFilter.start);
        filtered = filtered.filter(instance => 
          new Date(instance.startedAt) >= startDate
        );
      }
      
      if (dateFilter.end) {
        const endDate = new Date(dateFilter.end);
        filtered = filtered.filter(instance => 
          new Date(instance.startedAt) <= endDate
        );
      }
      
      // Mise à jour de la pagination pour les résultats filtrés
      const totalItems = filtered.length;
      const totalPages = Math.ceil(totalItems / pagination.pageSize);
      
      // Adapter la page courante si nécessaire
      let currentPage = pagination.currentPage;
      if (currentPage >= totalPages && totalPages > 0) {
        currentPage = totalPages - 1;
      }
      
      setPagination(prev => ({
        ...prev,
        currentPage,
        totalItems,
        totalPages
      }));
      
      // Paginer les résultats filtrés localement
      const start = currentPage * pagination.pageSize;
      const end = start + pagination.pageSize;
      setFilteredInstances(filtered.slice(start, end));
    } else {
      // Pour les filtres simples (statut, type), on utilise ce qu'on a déjà récupéré du serveur
      setFilteredInstances(workflowInstances);
    }
  }, [workflowInstances, searchTerm, dateFilter, pagination.pageSize, pagination.currentPage]);
  
  // Récupération des instances de workflow
  const fetchWorkflowInstances = async () => {
    try {
      setError(null);
      setLoading(true);
      
      // Préparer les paramètres de requête
      const params = {
        page: pagination.currentPage,
        size: pagination.pageSize
      };
      
      // Ajouter les filtres côté serveur
      if (statusFilter !== 'all') {
        params.status = statusFilter;
      }
      
      if (typeFilter !== 'all') {
        params.workflowCode = typeFilter;
      }
      
      // Utiliser l'API pour récupérer les données paginées
      const response = await apiService.declarationWorkflow.getAll(params);
      
      if (response && response.workflows && Array.isArray(response.workflows)) {
        // Adapter les données au format attendu par le composant
        const formattedInstances = response.workflows.map(workflow => ({
          id: workflow.id || workflow.reference,
          reference: workflow.reference,
          workflowCode: workflow.workflowCode,
          workflowName: workflow.name || workflow.workflowCode,
          initialState: workflow.initialState,
          currentState: workflow.currentState,
          finalState: workflow.finalState || false,
          completed: workflow.completed || workflow.status === 'COMPLETED',
          error: workflow.error || workflow.status === 'ERROR' || workflow.status === 'REJECTED',
          startedAt: workflow.startedAt || workflow.createdAt,
          lastUpdatedAt: workflow.lastUpdatedAt || workflow.updatedAt,
          context: workflow.context || {}
        }));
        
        setWorkflowInstances(formattedInstances);
        
        // Mettre à jour les informations de pagination à partir de la réponse
        if (response.totalElements !== undefined && response.totalPages !== undefined) {
          setPagination(prev => ({
            ...prev,
            totalItems: response.totalElements,
            totalPages: response.totalPages
          }));
        }
        
        // Si on ne filtre pas localement, utiliser directement les données
        if (!isLocalFiltering) {
          setFilteredInstances(formattedInstances);
        }
        
        setLoading(false);
        return formattedInstances;
      } else {
        console.error("Format de réponse invalide pour les instances de workflow:", response);
        setError("Format de réponse invalide pour les instances de workflow");
        setLoading(false);
        return [];
      }
    } catch (err) {
      console.error("Erreur lors du chargement des instances de workflow:", err);
      setError("Impossible de charger les instances de workflow. Veuillez réessayer.");
      setLoading(false);
      return [];
    }
  };
  
  // Gestion de la pagination
  const handlePageChange = (newPage) => {
    setPagination(prev => ({
      ...prev,
      currentPage: newPage
    }));
  };
  
  const handlePageSizeChange = (newSize) => {
    setPagination(prev => ({
      ...prev,
      pageSize: newSize,
      currentPage: 0 // Réinitialiser à la première page lors du changement de taille
    }));
  };
  
  // Récupération des définitions de workflow
  const fetchWorkflowDefinitions = async () => {
    try {
      setError(null);
      // Utiliser l'API existante
      const response = await apiService.workflow.getAllWorkflowDefinitions();
      
      // Récupérer les workflows à partir de la réponse
      const definitions = response?.workflows || [];
      setWorkflowDefinitions(definitions);
      return definitions;
    } catch (err) {
      console.error("Erreur lors du chargement des définitions de workflow:", err);
      setError("Impossible de charger les définitions de workflow. Veuillez réessayer.");
      return [];
    }
  };
  
  // Récupérer les détails d'une instance de workflow
  const fetchWorkflowDetails = async (instanceId) => {
    setHistoryLoading(true);
    setError(null);
    try {
      // Trouver l'instance concernée pour récupérer sa référence
      const instance = workflowInstances.find(inst => inst.id === instanceId);
      if (!instance) {
        throw new Error("Instance de workflow non trouvée");
      }
      
      // Récupérer l'historique avec l'API existante
      const historyResponse = await apiService.workflow.getWorkflowHistory(instance.reference);
      // Récupérer les détails avec l'API existante pour obtenir les transitions disponibles
      const detailsResponse = await apiService.workflow.getWorkflowDetails(instance.reference);
      
      // Vérifier si les réponses contiennent des données
      const history = Array.isArray(historyResponse) ? historyResponse : [];
      const availableTransitions = detailsResponse?.availableTransitions || [];
      
      setInstanceHistory(history);
      setAvailableTransitions(availableTransitions);
    } catch (err) {
      console.error("Erreur lors du chargement des détails du workflow:", err);
      setError("Impossible de charger les détails du workflow. Veuillez réessayer.");
      setInstanceHistory([]);
      setAvailableTransitions([]);
    } finally {
      setHistoryLoading(false);
    }
  };
  
  // Mettre à jour les statistiques des workflows
  const updateWorkflowStats = (instances) => {
    // Si on a filtré, on veut afficher les statistiques sur l'ensemble des données
    // Pour cela, on doit faire une requête supplémentaire pour obtenir le total
    if (isLocalFiltering) {
      try {
        apiService.declarationWorkflow.getAll({
          page: 0,
          size: 1, // On ne veut que les métadonnées, pas tous les workflows
          countOnly: true // Option hypothétique pour juste compter
        }).then(response => {
          if (response && response.totalElements) {
            updateWorkflowStatsWithTotal(instances, response.totalElements);
          }
        });
      } catch (error) {
        console.error("Erreur lors de la récupération des statistiques:", error);
        // En cas d'échec, on utilise les données locales
        updateWorkflowStatsWithTotal(instances, instances.length);
      }
    } else {
      updateWorkflowStatsWithTotal(instances, pagination.totalItems);
    }
  };
  
  const updateWorkflowStatsWithTotal = (instances, total) => {
    const stats = {
      total: total,
      active: 0,
      completed: 0,
      failed: 0,
      byType: {}
    };
    
    // Compter les différentes statistiques sur les instances visibles
    instances.forEach(instance => {
      // Compter par état
      if (instance.error || instance.currentState === "REJECTED") {
        stats.failed++;
      } else if (instance.completed) {
        stats.completed++;
      } else {
        stats.active++;
      }
      
      // Compter par type de workflow
      if (!stats.byType[instance.workflowCode]) {
        stats.byType[instance.workflowCode] = {
          name: instance.workflowName || instance.workflowCode,
          count: 0,
          active: 0,
          completed: 0,
          failed: 0
        };
      }
      
      stats.byType[instance.workflowCode].count++;
      
      if (instance.error || instance.currentState === "REJECTED") {
        stats.byType[instance.workflowCode].failed++;
      } else if (instance.completed) {
        stats.byType[instance.workflowCode].completed++;
      } else {
        stats.byType[instance.workflowCode].active++;
      }
    });
    
    // Ajustement des statistiques pour tenir compte de la pagination
    if (instances.length < total) {
      // Calculer les proportions (approximation)
      const ratio = total / instances.length;
      stats.active = Math.round(stats.active * ratio);
      stats.completed = Math.round(stats.completed * ratio);
      stats.failed = Math.round(stats.failed * ratio);
      
      // Assurer que la somme correspond au total
      const sum = stats.active + stats.completed + stats.failed;
      if (sum !== total) {
        // Ajuster le nombre d'actifs (catégorie souvent majoritaire)
        stats.active += (total - sum);
      }
      
      // Ajuster les statistiques par type (approximation)
      Object.keys(stats.byType).forEach(typeCode => {
        stats.byType[typeCode].count = Math.round(stats.byType[typeCode].count * ratio);
        stats.byType[typeCode].active = Math.round(stats.byType[typeCode].active * ratio);
        stats.byType[typeCode].completed = Math.round(stats.byType[typeCode].completed * ratio);
        stats.byType[typeCode].failed = Math.round(stats.byType[typeCode].failed * ratio);
      });
    }
    
    setWorkflowStats(stats);
  };
  
  // Appliquer les filtres et réinitialiser la pagination
  const applyFilters = () => {
    setPagination(prev => ({
      ...prev,
      currentPage: 0 // Revenir à la première page
    }));
    
    // Si les filtres sont côté serveur, déclencher une nouvelle requête
    if (!isLocalFiltering) {
      fetchWorkflowInstances();
    }
    
    setShowFilters(false);
  };
  
  // Traiter une action sur une instance de workflow
  const handleProcessEvent = async (instanceId, event) => {
    setActionLoading(true);
    setError(null);
    
    try {
      // Trouver l'instance concernée pour récupérer sa référence
      const instance = workflowInstances.find(inst => inst.id === instanceId);
      if (!instance) {
        throw new Error("Instance de workflow non trouvée");
      }
      
      // Appeler l'API pour traiter l'événement
      await apiService.workflow.processEvent(
        instance.reference,
        event,
        { timestamp: new Date().toISOString() }
      );
      
      setSuccessMessage(`L'événement a été traité avec succès.`);
      setTimeout(() => setSuccessMessage(null), 3000);
      
      // Rafraîchir les données après un court délai
      setTimeout(async () => {
        await fetchWorkflowInstances();
        
        // Si l'instance est développée, mettre à jour ses détails
        if (expandedInstanceId === instanceId) {
          fetchWorkflowDetails(instanceId);
        }
      }, 1000);
      
    } catch (err) {
      console.error("Erreur lors du traitement de l'événement:", err);
      setError(`Impossible de traiter l'événement. ${err.message || "Veuillez réessayer."}`);
    } finally {
      setActionLoading(false);
    }
  };
  
  // Annuler une instance de workflow
  const handleCancelWorkflow = async (instanceId) => {
    setActionLoading(true);
    setError(null);
    
    try {
      // Demander confirmation
      const confirmation = window.confirm("Êtes-vous sûr de vouloir annuler ce workflow ? Cette action est irréversible.");
      if (!confirmation) {
        setActionLoading(false);
        return;
      }
      
      // Trouver l'instance concernée pour récupérer sa référence
      const instance = workflowInstances.find(inst => inst.id === instanceId);
      if (!instance) {
        throw new Error("Instance de workflow non trouvée");
      }
      
      // Appeler l'API pour annuler le workflow
      await apiService.workflow.processEvent(
        instance.reference,
        "CANCEL_WORKFLOW", // Événement d'annulation - Cet événement doit être géré par le backend
        { reason: "Annulation manuelle par l'utilisateur" }
      );
      
      setSuccessMessage(`Le workflow a été annulé avec succès.`);
      setTimeout(() => setSuccessMessage(null), 3000);
      
      // Rafraîchir les données
      await fetchWorkflowInstances();
      
    } catch (err) {
      console.error("Erreur lors de l'annulation du workflow:", err);
      setError(`Impossible d'annuler le workflow. ${err.message || "Veuillez réessayer."}`);
    } finally {
      setActionLoading(false);
    }
  };
  
  // Développer/réduire une instance
  const toggleInstanceExpansion = (instanceId) => {
    if (expandedInstanceId === instanceId) {
      setExpandedInstanceId(null);
      setInstanceHistory([]);
      setAvailableTransitions([]);
    } else {
      setExpandedInstanceId(instanceId);
      fetchWorkflowDetails(instanceId);
    }
  };
  
  // Afficher les détails d'une instance
  const handleShowDetails = (instance) => {
    setSelectedInstance(instance);
    
    // Trouver la définition de workflow correspondante
    const definition = workflowDefinitions.find(def => def.code === instance.workflowCode);
    if (definition) {
      setSelectedDefinition(definition);
    }
    
    setShowDetailsModal(true);
    fetchWorkflowDetails(instance.id);
  };
  
  // Formater le nom d'un état pour affichage
  const formatStateName = (state) => {
    if (!state) return '';
    
    // Remplacer les underscores par des espaces et capitaliser chaque mot
    return state.split('_')
      .map(word => word.charAt(0) + word.slice(1).toLowerCase())
      .join(' ');
  };
  
  // Obtenir l'icône pour un type de workflow
  const getWorkflowIcon = (workflowCode) => {
    if (!workflowCode) return <GitBranch />;
    
    if (workflowCode.includes('ANNULATION')) {
      return <RotateCcw />;
    } else if (workflowCode.includes('INVERSION')) {
      return <ArrowRightLeft />;
    } else if (workflowCode.includes('VIREMENT')) {
      return <Send />;
    } else if (workflowCode.includes('CHEQUE')) {
      return <FileText />;
    } else if (workflowCode.includes('RETOUR_FONDS')) {
      return <RotateCcw />;
    } else if (workflowCode.includes('SYSTAC')) {
      return <Send />;
    }
    
    return <GitBranch />;
  };
  
  // Rendu d'un badge de statut en fonction de l'état du workflow
  const renderStatusBadge = (instance) => {
    if (instance.error || instance.currentState === "REJECTED") {
      return <StatusBadge status="inactive" customLabel="Échoué" />;
    }
    
    if (instance.completed) {
      return <StatusBadge status="active" customLabel="Terminé" />;
    }
    
    if (instance.currentState === instance.initialState) {
      return <StatusBadge status="warning" customLabel="Démarré" />;
    }
    
    return <StatusBadge status="warning" customLabel="En cours" />;
  };
  
  // Calculer la progression estimée du workflow
  const calculateProgress = (instance) => {
    if (!instance) return 0;
    
    if (instance.completed) return 100;
    if (instance.error) return 100;
    
    // Si nous avons l'information de progression depuis le backend, l'utiliser
    if (instance.progress !== undefined && typeof instance.progress === 'number') {
      return instance.progress;
    }
    
    // Sinon estimer la progression en fonction de l'historique
    if (instanceHistory.length > 0 && instance.id === expandedInstanceId) {
      // Si nous avons l'historique de l'instance actuelle, estimer la progression
      // en fonction du nombre de transitions déjà effectuées
      // Cette méthode est approximative et peut être améliorée avec des informations du backend
      return Math.min(Math.floor((instanceHistory.length / 5) * 100), 95);
    }
    
    // Par défaut, retourner 50%
    return 50;
  };
  
  // Réinitialiser les filtres
  const resetFilters = () => {
    setSearchTerm('');
    setStatusFilter('all');
    setTypeFilter('all');
    setDateFilter({
      start: null,
      end: null
    });
    
    // Réinitialiser la pagination
    setPagination(prev => ({
      ...prev,
      currentPage: 0
    }));
    
    // Réinitialiser le mode de filtrage
    setIsLocalFiltering(false);
    
    // Récupérer les données sans filtre
    fetchWorkflowInstances();
  };
  
  // Rendu du composant
  return (
    <div className="space-y-6">
      {/* En-tête de la page */}
      <div className="flex flex-col md:flex-row md:justify-between md:items-center gap-4">
        <div>
          <h1 className="text-2xl font-bold text-gray-900 dark:text-white flex items-center">
            <GitBranch className="h-6 w-6 mr-2 text-blue-500" />
            Suivi des instances de workflow
          </h1>
          <p className="text-gray-500 dark:text-gray-400">
            Surveillez et gérez les instances de workflow CEMAC-ACH en cours d'exécution
          </p>
        </div>
        
        <div className="flex flex-wrap gap-2">
          <Button
            variant="outline" 
            icon={RefreshCw}
            onClick={fetchWorkflowInstances}
            loading={loading}
          >
            Actualiser
          </Button>
          <Button
            variant={autoRefresh ? "primary" : "outline"}
            icon={Clock}
            onClick={() => setAutoRefresh(!autoRefresh)}
          >
            {autoRefresh ? `Auto (${timeLeft}s)` : "Auto-refresh"}
          </Button>
          <Button
            variant={showWorkflowDiagram ? "primary" : "outline"}
            icon={GitBranch}
            onClick={() => setShowWorkflowDiagram(!showWorkflowDiagram)}
          >
            Diagrammes
          </Button>
        </div>
      </div>
      
      {/* Messages de succès et d'erreur */}
      {successMessage && (
        <div className="bg-green-50 dark:bg-green-900/20 border-l-4 border-green-500 p-4 rounded-md">
          <div className="flex items-center">
            <Check className="h-5 w-5 text-green-500 mr-2" />
            <p className="text-green-700 dark:text-green-300">{successMessage}</p>
          </div>
        </div>
      )}
      
      {error && (
        <div className="bg-red-50 dark:bg-red-900/20 border-l-4 border-red-500 p-4 rounded-md">
          <div className="flex items-center">
            <AlertTriangle className="h-5 w-5 text-red-500 mr-2" />
            <p className="text-red-700 dark:text-red-300">{error}</p>
          </div>
        </div>
      )}
      
      {/* Statistiques et résumé */}
      {statsVisible && workflowStats.total > 0 && (
        <Card 
          title="Statistiques des Workflows" 
          icon={BarChart2}
          actions={
            <Button
              variant="text"
              size="sm"
              icon={ChevronDown}
              onClick={() => setStatsVisible(false)}
            />
          }
        >
          <div className="mb-4">
            <div className="grid grid-cols-1 sm:grid-cols-3 gap-4">
              <div className="bg-blue-50 dark:bg-blue-900/20 p-4 rounded-lg">
                <div className="flex justify-between items-center">
                  <div>
                    <p className="text-sm text-blue-700 dark:text-blue-300">Total</p>
                    <p className="text-2xl font-bold text-blue-900 dark:text-blue-100">{workflowStats.total}</p>
                  </div>
                  <div className="bg-blue-100 dark:bg-blue-800 p-2 rounded-full">
                    <GitBranch className="h-5 w-5 text-blue-500" />
                  </div>
                </div>
              </div>
              
              <div className="bg-yellow-50 dark:bg-yellow-900/20 p-4 rounded-lg">
                <div className="flex justify-between items-center">
                  <div>
                    <p className="text-sm text-yellow-700 dark:text-yellow-300">En cours</p>
                    <p className="text-2xl font-bold text-yellow-900 dark:text-yellow-100">{workflowStats.active}</p>
                  </div>
                  <div className="bg-yellow-100 dark:bg-yellow-800 p-2 rounded-full">
                    <Clock className="h-5 w-5 text-yellow-500" />
                  </div>
                </div>
              </div>
              
              <div className="bg-green-50 dark:bg-green-900/20 p-4 rounded-lg">
                <div className="flex justify-between items-center">
                  <div>
                    <p className="text-sm text-green-700 dark:text-green-300">Terminés</p>
                    <p className="text-2xl font-bold text-green-900 dark:text-green-100">{workflowStats.completed}</p>
                  </div>
                  <div className="bg-green-100 dark:bg-green-800 p-2 rounded-full">
                    <CheckCircle className="h-5 w-5 text-green-500" />
                  </div>
                </div>
              </div>
            </div>
          </div>
          
          {/* Détails par type de workflow */}
          <div className="mt-4">
            <h4 className="text-sm font-medium text-gray-700 dark:text-gray-300 mb-2">Par type de workflow</h4>
            <div className="space-y-2">
              {Object.keys(workflowStats.byType).map(wfCode => (
                <div key={wfCode} className="flex items-center justify-between p-2 bg-gray-50 dark:bg-gray-800 rounded-lg">
                  <div className="flex items-center">
                    <div className="p-2 mr-3 bg-blue-100 dark:bg-blue-900/30 rounded-full">
                      {getWorkflowIcon(wfCode)}
                    </div>
                    <div>
                      <p className="text-sm font-medium text-gray-900 dark:text-white">{workflowStats.byType[wfCode].name}</p>
                      <p className="text-xs text-gray-500 dark:text-gray-400">{wfCode}</p>
                    </div>
                  </div>
                  <div className="flex space-x-2">
                    <div className="flex items-center text-sm">
                      <div className="w-3 h-3 rounded-full bg-yellow-400 mr-1"></div>
                      <span>{workflowStats.byType[wfCode].active}</span>
                    </div>
                    <div className="flex items-center text-sm">
                      <div className="w-3 h-3 rounded-full bg-green-400 mr-1"></div>
                      <span>{workflowStats.byType[wfCode].completed}</span>
                    </div>
                    <div className="flex items-center text-sm">
                      <div className="w-3 h-3 rounded-full bg-red-400 mr-1"></div>
                      <span>{workflowStats.byType[wfCode].failed}</span>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </Card>
      )}
      
      {/* Filtres de recherche */}
      <div className="mb-4 flex flex-col sm:flex-row sm:justify-between sm:items-center gap-4">
        <div className="flex-1 relative">
          <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
            <Search className="h-5 w-5 text-gray-400" />
          </div>
          <input
            type="text"
            className="block w-full pl-10 pr-3 py-2 border border-gray-300 dark:border-gray-600 rounded-md bg-white dark:bg-gray-700 text-gray-900 dark:text-white focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
            placeholder="Rechercher par référence, état, etc..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
        </div>
        <div className="flex gap-2">
          <Button
            variant={showFilters ? "primary" : "outline"}
            icon={Filter}
            onClick={() => setShowFilters(!showFilters)}
          >
            Filtres
          </Button>
        </div>
      </div>
      
      {showFilters && (
        <Card className="mb-6">
          <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
            <div>
              <label htmlFor="statusFilter" className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
                Statut
              </label>
              <select
                id="statusFilter"
                value={statusFilter}
                onChange={(e) => setStatusFilter(e.target.value)}
                className="block w-full px-3 py-2 border border-gray-300 dark:border-gray-600 rounded-md bg-white dark:bg-gray-700 text-gray-900 dark:text-white focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
              >
                <option value="all">Tous les statuts</option>
                <option value="active">En cours</option>
                <option value="completed">Terminés</option>
                <option value="failed">Échoués</option>
                {/* États spécifiques des workflows */}
                <option value="RECEPTION_CBS">Réception CBS</option>
                <option value="VALIDATION_ENTREE">Validation entrée</option>
                <option value="VERIFICATION_ELIGIBILITE">Vérification éligibilité</option>
                <option value="TRANSFORMATION">Transformation</option>
                <option value="SIGNED">Signé</option>
                <option value="EMISSION">Émission</option>
                <option value="RECEPTION_PSACH">Réception PS-ACH</option>
                <option value="VERIFICATION_SIGNATURE">Vérification signature</option>
              </select>
            </div>
            <div>
              <label htmlFor="typeFilter" className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
                Type de workflow
              </label>
              <select
                id="typeFilter"
                value={typeFilter}
                onChange={(e) => setTypeFilter(e.target.value)}
                className="block w-full px-3 py-2 border border-gray-300 dark:border-gray-600 rounded-md bg-white dark:bg-gray-700 text-gray-900 dark:text-white focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
              >
                <option value="all">Tous les types</option>
                {workflowDefinitions.map(def => (
                  <option key={def.code} value={def.code}>{def.name}</option>
                ))}
              </select>
            </div>
            <div>
              <label htmlFor="dateFilter" className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
                Date de création
              </label>
              <div className="grid grid-cols-2 gap-2">
                <div>
                  <input
                    type="date"
                    id="startDate"
                    value={dateFilter.start || ''}
                    onChange={(e) => setDateFilter({...dateFilter, start: e.target.value})}
                    className="block w-full px-3 py-2 border border-gray-300 dark:border-gray-600 rounded-md bg-white dark:bg-gray-700 text-gray-900 dark:text-white focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                    placeholder="Date début"
                  />
                </div>
                <div>
                  <input
                    type="date"
                    id="endDate"
                    value={dateFilter.end || ''}
                    onChange={(e) => setDateFilter({...dateFilter, end: e.target.value})}
                    className="block w-full px-3 py-2 border border-gray-300 dark:border-gray-600 rounded-md bg-white dark:bg-gray-700 text-gray-900 dark:text-white focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                    placeholder="Date fin"
                  />
                </div>
              </div>
            </div>
          </div>
          
          <div className="mt-4 flex justify-end space-x-2">
            <Button
              variant="outline"
              onClick={resetFilters}
            >
              Réinitialiser
            </Button>
            <Button
              variant="primary"
              onClick={applyFilters}
            >
              Appliquer
            </Button>
          </div>
        </Card>
      )}
      
      {/* Contrôles de pagination */}
      <div className="flex flex-wrap items-center justify-between mb-4 bg-white dark:bg-gray-800 p-2 rounded-lg shadow-sm">
        <div className="flex items-center space-x-2 mb-2 sm:mb-0">
          <label htmlFor="pageSize" className="text-sm text-gray-600 dark:text-gray-400">
            Afficher:
          </label>
          <select
            id="pageSize"
            value={pagination.pageSize}
            onChange={(e) => handlePageSizeChange(Number(e.target.value))}
            className="text-sm border border-gray-300 dark:border-gray-600 rounded-md bg-white dark:bg-gray-700 text-gray-900 dark:text-white focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
          >
            <option value={5}>5</option>
            <option value={10}>10</option>
            <option value={25}>25</option>
            <option value={50}>50</option>
            <option value={100}>100</option>
          </select>
          <span className="text-sm text-gray-600 dark:text-gray-400">
            Éléments par page
          </span>
        </div>
        
        <div className="flex items-center">
          <div className="text-sm text-gray-600 dark:text-gray-400 mr-4">
            {pagination.totalItems > 0 ? (
              <>
                {pagination.currentPage * pagination.pageSize + 1} - {Math.min((pagination.currentPage + 1) * pagination.pageSize, pagination.totalItems)} sur {pagination.totalItems}
              </>
            ) : (
              'Aucun résultat'
            )}
          </div>
          
          <div className="flex space-x-1">
            <Button
              variant="outline"
              size="sm"
              icon={ChevronsLeft}
              onClick={() => handlePageChange(0)}
              disabled={pagination.currentPage === 0 || loading}
              className="px-2"
            />
            <Button
              variant="outline"
              size="sm"
              icon={ChevronLeft}
              onClick={() => handlePageChange(pagination.currentPage - 1)}
              disabled={pagination.currentPage === 0 || loading}
              className="px-2"
            />
            
            {/* Afficher les numéros de page */}
            <div className="hidden sm:flex space-x-1">
              {Array.from({ length: Math.min(5, pagination.totalPages) }, (_, i) => {
                // Si nous avons plus de 5 pages, montrer les pages autour de la page courante
                let pageToShow;
                if (pagination.totalPages <= 5) {
                  pageToShow = i;
                } else {
                  // Calculer le centre des pages à afficher
                  const start = Math.max(0, Math.min(pagination.currentPage - 2, pagination.totalPages - 5));
                  pageToShow = start + i;
                }
                
                return (
                  <Button
                    key={pageToShow}
                    variant={pagination.currentPage === pageToShow ? "primary" : "outline"}
                    size="sm"
                    onClick={() => handlePageChange(pageToShow)}
                    disabled={loading}
                  >
                    {pageToShow + 1}
                  </Button>
                );
              })}
            </div>
            
            <Button
              variant="outline"
              size="sm"
              icon={ChevronRight}
              onClick={() => handlePageChange(pagination.currentPage + 1)}
              disabled={pagination.currentPage >= pagination.totalPages - 1 || loading}
              className="px-2"
            />
            <Button
              variant="outline"
              size="sm"
              icon={ChevronsRight}
              onClick={() => handlePageChange(pagination.totalPages - 1)}
              disabled={pagination.currentPage >= pagination.totalPages - 1 || loading}
              className="px-2"
            />
          </div>
        </div>
      </div>
      
      {/* Affichage du diagramme de workflow sélectionné */}
      {showWorkflowDiagram && (
        <Card
          title="Diagrammes de workflow"
          icon={GitBranch}
          actions={
            <Button
              variant="outline"
              size="sm"
              icon={X}
              onClick={() => setShowWorkflowDiagram(false)}
            >
              Fermer
            </Button>
          }
        >
          <div className="mb-4">
            <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-2">
              Sélectionner un workflow
            </label>
            <select
              value={selectedDefinition?.code || ''}
              onChange={(e) => {
                const def = workflowDefinitions.find(d => d.code === e.target.value);
                setSelectedDefinition(def || null);
              }}
              className="block w-full px-3 py-2 border border-gray-300 dark:border-gray-600 rounded-md bg-white dark:bg-gray-700 text-gray-900 dark:text-white focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
            >
              <option value="">Sélectionner un workflow</option>
              {workflowDefinitions.map(def => (
                <option key={def.code} value={def.code}>{def.name}</option>
              ))}
            </select>
          </div>
          
          {selectedDefinition && (
            <WorkflowStateTransitionDiagram
              workflowDefinition={selectedDefinition}
              onRefresh={() => fetchWorkflowDefinitions()}
            />
          )}
        </Card>
      )}
      
      {/* Liste des instances de workflow */}
      {loading ? (
        <div className="flex justify-center items-center h-64">
          <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-blue-500"></div>
        </div>
      ) : filteredInstances.length === 0 ? (
        <div className="flex flex-col items-center justify-center h-64 bg-white dark:bg-gray-800 rounded-lg shadow">
          <GitBranch className="h-16 w-16 text-gray-300 dark:text-gray-600 mb-4" />
          <h3 className="text-lg font-medium text-gray-900 dark:text-white">Aucune instance de workflow trouvée</h3>
          <p className="text-gray-500 dark:text-gray-400 mt-2 text-center max-w-md">
            Aucune instance de workflow ne correspond à vos critères de recherche. Vous pouvez modifier vos filtres ou attendre que de nouvelles instances soient créées.
          </p>
        </div>
      ) : (
        <div className="space-y-4">
          {filteredInstances.map(instance => (
            <Card
              key={instance.id}
              className={`overflow-hidden transition-all ${expandedInstanceId === instance.id ? 'ring-2 ring-blue-500' : ''}`}
            >
              {/* En-tête de l'instance */}
              <div 
                className="px-4 py-3 flex justify-between items-center cursor-pointer hover:bg-gray-50 dark:hover:bg-gray-700/50"
                onClick={() => toggleInstanceExpansion(instance.id)}
              >
                <div className="flex items-center">
                  <div className="p-2 mr-3 bg-blue-100 dark:bg-blue-900/30 rounded-full">
                    {getWorkflowIcon(instance.workflowCode)}
                  </div>
                  <div>
                    <div className="flex items-center">
                      <h3 className="text-lg font-semibold text-gray-900 dark:text-white mr-2">
                        {instance.reference}
                      </h3>
                      {renderStatusBadge(instance)}
                    </div>
                    <p className="text-sm text-gray-500 dark:text-gray-400">
                      {instance.workflowName} - <span className="font-medium">{formatStateName(instance.currentState)}</span>
                    </p>
                  </div>
                </div>
                <div className="flex items-center">
                  <p className="text-sm text-gray-500 dark:text-gray-400 mr-4">
                    <Calendar className="h-4 w-4 inline mr-1" />
                    <DateFormatter date={instance.startedAt} format="short" />
                  </p>
                  {expandedInstanceId === instance.id ? (
                    <ChevronDown className="h-5 w-5 text-gray-400" />
                  ) : (
                    <ChevronRight className="h-5 w-5 text-gray-400" />
                  )}
                </div>
              </div>
              
              {/* Barre de progression */}
              <div className="h-1.5 bg-gray-200 dark:bg-gray-700">
                <div
                  className={`h-full ${
                    instance.error ? 'bg-red-500' : instance.completed ? 'bg-green-500' : 'bg-blue-500'
                  }`}
                  style={{ width: `${calculateProgress(instance)}%` }}
                ></div>
              </div>
              
              {/* Détails développés */}
              {expandedInstanceId === instance.id && (
                <div className="p-4 border-t border-gray-200 dark:border-gray-700">
                  <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-4">
                    <div>
                      <h4 className="text-sm font-medium text-gray-700 dark:text-gray-300 mb-2">
                        Informations du workflow
                      </h4>
                      <div className="space-y-2">
                        <div className="flex justify-between">
                          <span className="text-sm text-gray-500 dark:text-gray-400">Code workflow:</span>
                          <span className="text-sm font-medium text-gray-900 dark:text-white">{instance.workflowCode}</span>
                        </div>
                        <div className="flex justify-between">
                          <span className="text-sm text-gray-500 dark:text-gray-400">État initial:</span>
                          <span className="text-sm font-medium text-gray-900 dark:text-white">{formatStateName(instance.initialState)}</span>
                        </div>
                        <div className="flex justify-between">
                          <span className="text-sm text-gray-500 dark:text-gray-400">État actuel:</span>
                          <span className="text-sm font-medium text-gray-900 dark:text-white">{formatStateName(instance.currentState)}</span>
                        </div>
                        <div className="flex justify-between">
                          <span className="text-sm text-gray-500 dark:text-gray-400">Terminé:</span>
                          <span className="text-sm font-medium text-gray-900 dark:text-white">{instance.completed ? 'Oui' : 'Non'}</span>
                        </div>
                        {instance.error && (
                          <div className="flex justify-between">
                            <span className="text-sm text-gray-500 dark:text-gray-400">Erreur:</span>
                            <span className="text-sm font-medium text-red-500">{
                              instance.context?.errorMessage || 
                              instance.errorMessage || 
                              'Erreur inconnue'
                            }</span>
                          </div>
                        )}
                      </div>
                    </div>
                    
                    <div>
                      <h4 className="text-sm font-medium text-gray-700 dark:text-gray-300 mb-2">
                        Contexte d'exécution
                      </h4>
                      {instance.context ? (
                        <div className="space-y-2">
                          {/* Afficher les données de contexte en fonction de leur type */}
                          {typeof instance.context === 'object' ? 
                            Object.entries(instance.context).map(([key, value]) => {
                              // Ignorer les valeurs complexes ou nulles
                              if (value === null || typeof value === 'object') return null;
                              
                              // Formater les valeurs numériques pour les montants
                              let displayValue = value;
                              if (key.toLowerCase().includes('montant') && typeof value === 'number') {
                                displayValue = new Intl.NumberFormat('fr-FR', {
                                  style: 'currency',
                                  currency: instance.context.devise || 'XAF',
                                  minimumFractionDigits: 0
                                }).format(value);
                              }
                              
                              return (
                                <div key={key} className="flex justify-between">
                                  <span className="text-sm text-gray-500 dark:text-gray-400">
                                    {key.charAt(0).toUpperCase() + key.slice(1).replace(/([A-Z])/g, ' $1').toLowerCase()}:
                                  </span>
                                  <span className="text-sm font-medium text-gray-900 dark:text-white">
                                    {String(displayValue)}
                                  </span>
                                </div>
                              );
                            })
                            : 
                            <p className="text-sm text-gray-500 dark:text-gray-400">
                              {JSON.stringify(instance.context)}
                            </p>
                          }
                        </div>
                      ) : (
                        <p className="text-sm text-gray-500 dark:text-gray-400">
                          Aucun contexte disponible
                        </p>
                      )}
                    </div>
                  </div>
                  
                  {/* Historique du workflow */}
                  <div className="mb-4">
                    <h4 className="text-sm font-medium text-gray-700 dark:text-gray-300 mb-2 flex items-center">
                      <Clock8 className="h-4 w-4 mr-1" />
                      Historique des transitions
                    </h4>
                    
                    {historyLoading ? (
                      <div className="flex justify-center items-center py-4">
                        <Loader className="h-6 w-6 text-blue-500 animate-spin" />
                      </div>
                    ) : instanceHistory.length === 0 ? (
                      <p className="text-sm text-gray-500 dark:text-gray-400 py-2">
                        Aucun historique disponible
                      </p>
                    ) : (
                      <div className="space-y-1 pl-4 border-l-2 border-gray-200 dark:border-gray-700">
                        {instanceHistory.map((entry, index) => (
                          <div key={entry.id || index} className="relative">
                            <div className="absolute -left-[9px] mt-1.5">
                              <div className="w-4 h-4 rounded-full bg-blue-500"></div>
                            </div>
                            <div className="pl-4 pb-3">
                              <p className="text-sm font-medium text-gray-900 dark:text-white">
                                {formatStateName(entry.fromState)} &rarr; {formatStateName(entry.toState)}
                              </p>
                              <p className="text-xs text-gray-500 dark:text-gray-400">
                                <DateFormatter date={entry.transitionDate || entry.timestamp} format="short" showTime /> | Événement: {entry.event}
                              </p>
                              {(entry.comment || entry.contextSnapshot?.comment) && (
                                <p className="text-xs text-gray-600 dark:text-gray-300 mt-1">
                                  {entry.comment || entry.contextSnapshot?.comment}
                                </p>
                              )}
                              {(entry.initiatedBy || entry.user) && (
                                <p className="text-xs text-gray-500 dark:text-gray-400 mt-1">
                                  Utilisateur: {entry.initiatedBy || entry.user}
                                </p>
                              )}
                            </div>
                          </div>
                        ))}
                      </div>
                    )}
                  </div>
                  
                  {/* Actions disponibles */}
                  <div className="border-t border-gray-200 dark:border-gray-700 pt-4">
                    <h4 className="text-sm font-medium text-gray-700 dark:text-gray-300 mb-2">
                      Actions disponibles
                    </h4>
                    
                    <div className="flex flex-wrap gap-2">
                      {availableTransitions.length > 0 ? (
                        <>
                          {availableTransitions.map(transition => (
                            <Button
                              key={transition.event}
                              variant="outline"
                              size="sm"
                              onClick={() => handleProcessEvent(instance.id, transition.event)}
                              disabled={actionLoading}
                            >
                              {transition.event}
                            </Button>
                          ))}
                        </>
                      ) : (
                        <p className="text-sm text-gray-500 dark:text-gray-400">
                          {instance.completed ? 'Workflow terminé' : 'Aucune action disponible'}
                        </p>
                      )}
                      
                      <div className="ml-auto">
                        <Button
                          variant="outline"
                          size="sm"
                          icon={Eye}
                          onClick={() => handleShowDetails(instance)}
                        >
                          Détails
                        </Button>
                        
                        {!instance.completed && (
                          <Button
                            variant="outline"
                            size="sm"
                            icon={XSquare}
                            onClick={() => handleCancelWorkflow(instance.id)}
                            className="ml-2 text-red-500 border-red-300 hover:bg-red-50"
                            disabled={actionLoading}
                          >
                            Annuler
                          </Button>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </Card>
          ))}
        </div>
      )}
      
      {/* Modal de détail d'une instance */}
      {showDetailsModal && selectedInstance && (
        <div className="fixed inset-0 z-50 overflow-auto bg-black bg-opacity-50 flex items-center justify-center p-4">
          <div className="bg-white dark:bg-gray-800 rounded-lg max-w-4xl w-full">
            <div className="p-6">
              <div className="flex justify-between items-center mb-4">
                <h3 className="text-xl font-semibold text-gray-900 dark:text-white flex items-center">
                  <GitBranch className="w-5 h-5 mr-2 text-blue-500" />
                  Détails du workflow {selectedInstance.reference}
                </h3>
                <button
                  className="text-gray-400 hover:text-gray-500 dark:hover:text-gray-300"
                  onClick={() => setShowDetailsModal(false)}
                >
                  <X className="w-6 h-6" />
                </button>
              </div>
              
              <div className="space-y-6">
                {/* Informations principales */}
                <div>
                  <h4 className="text-lg font-medium text-gray-900 dark:text-white mb-2">
                    Informations générales
                  </h4>
                  <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                    <div>
                      <p className="text-sm text-gray-500 dark:text-gray-400">
                        Workflow:
                      </p>
                      <p className="text-sm font-medium text-gray-900 dark:text-white">
                        {selectedInstance.workflowName} ({selectedInstance.workflowCode})
                      </p>
                    </div>
                    <div>
                      <p className="text-sm text-gray-500 dark:text-gray-400">
                        Référence:
                      </p>
                      <p className="text-sm font-medium text-gray-900 dark:text-white">
                        {selectedInstance.reference}
                      </p>
                    </div>
                    <div>
                      <p className="text-sm text-gray-500 dark:text-gray-400">
                        Date de création:
                      </p>
                      <p className="text-sm font-medium text-gray-900 dark:text-white">
                        <DateFormatter date={selectedInstance.startedAt} format="full" showTime />
                      </p>
                    </div>
                    <div>
                      <p className="text-sm text-gray-500 dark:text-gray-400">
                        Dernière mise à jour:
                      </p>
                      <p className="text-sm font-medium text-gray-900 dark:text-white">
                      <DateFormatter date={selectedInstance.lastUpdatedAt} format="full" showTime />
                      </p>
                    </div>
                    <div>
                      <p className="text-sm text-gray-500 dark:text-gray-400">
                        État initial:
                      </p>
                      <p className="text-sm font-medium text-gray-900 dark:text-white">
                        {formatStateName(selectedInstance.initialState)}
                      </p>
                    </div>
                    <div>
                      <p className="text-sm text-gray-500 dark:text-gray-400">
                        État actuel:
                      </p>
                      <p className="text-sm font-medium text-gray-900 dark:text-white">
                        {formatStateName(selectedInstance.currentState)}
                      </p>
                    </div>
                    <div>
                      <p className="text-sm text-gray-500 dark:text-gray-400">
                        Statut:
                      </p>
                      <div className="text-sm font-medium">
                        {renderStatusBadge(selectedInstance)}
                      </div>
                    </div>
                    <div>
                      <p className="text-sm text-gray-500 dark:text-gray-400">
                        Progression:
                      </p>
                      <div className="w-full bg-gray-200 rounded-full h-2.5 dark:bg-gray-700">
                        <div 
                          className={`h-2.5 rounded-full ${
                            selectedInstance.error ? 'bg-red-500' : selectedInstance.completed ? 'bg-green-500' : 'bg-blue-500'
                          }`}
                          style={{ width: `${calculateProgress(selectedInstance)}%` }}
                        ></div>
                      </div>
                    </div>
                  </div>
                </div>
                
                {/* Contexte d'exécution */}
                <div>
                  <h4 className="text-lg font-medium text-gray-900 dark:text-white mb-2">
                    Contexte d'exécution
                  </h4>
                  {selectedInstance.context ? (
                    <div className="bg-gray-50 dark:bg-gray-700 p-4 rounded-lg">
                      <pre className="text-sm text-gray-800 dark:text-gray-200 whitespace-pre-wrap">
                        {JSON.stringify(selectedInstance.context, null, 2)}
                      </pre>
                    </div>
                  ) : (
                    <p className="text-sm text-gray-500 dark:text-gray-400">
                      Aucun contexte disponible pour cette instance.
                    </p>
                  )}
                </div>
                
                {/* Historique des transitions */}
                <div>
                  <h4 className="text-lg font-medium text-gray-900 dark:text-white mb-2 flex items-center">
                    <Clock8 className="w-5 h-5 mr-2 text-blue-500" />
                    Historique des transitions
                  </h4>
                  
                  {historyLoading ? (
                    <div className="flex justify-center items-center py-8">
                      <Loader className="w-8 h-8 text-blue-500 animate-spin" />
                    </div>
                  ) : instanceHistory.length === 0 ? (
                    <p className="text-sm text-gray-500 dark:text-gray-400 py-4">
                      Aucun historique disponible pour cette instance.
                    </p>
                  ) : (
                    <div className="space-y-2 pl-4 border-l-2 border-gray-200 dark:border-gray-700">
                      {instanceHistory.map((entry, index) => (
                        <div key={entry.id || index} className="relative">
                          <div className="absolute -left-[25px] mt-1.5">
                            <div className="w-5 h-5 rounded-full bg-blue-500"></div>
                          </div>
                          <div className="pl-4 pb-4">
                            <p className="text-sm font-medium text-gray-900 dark:text-white">
                              {formatStateName(entry.fromState)} &rarr; {formatStateName(entry.toState)}
                            </p>
                            <p className="text-xs text-gray-500 dark:text-gray-400">
                              <DateFormatter date={entry.transitionDate || entry.timestamp} format="full" showTime /> | Événement: {entry.event}
                            </p>
                            {(entry.comment || entry.contextSnapshot?.comment) && (
                              <p className="text-xs text-gray-600 dark:text-gray-300 mt-1">
                                {entry.comment || entry.contextSnapshot?.comment}
                              </p>
                            )}
                            {(entry.initiatedBy || entry.user) && (
                              <p className="text-xs text-gray-500 dark:text-gray-400 mt-1">
                                Utilisateur: {entry.initiatedBy || entry.user}
                              </p>
                            )}
                          </div>
                        </div>
                      ))}
                    </div>
                  )}
                </div>
                
                {/* Diagramme du workflow */}
                {selectedDefinition && (
                  <div>
                    <h4 className="text-lg font-medium text-gray-900 dark:text-white mb-2 flex items-center">
                      <GitBranch className="w-5 h-5 mr-2 text-blue-500" />
                      Diagramme du workflow
                    </h4>
                    
                    <WorkflowStateTransitionDiagram
                      workflowDefinition={selectedDefinition}
                      onRefresh={() => fetchWorkflowDefinitions()}
                    />
                  </div>
                )}
                
                {/* Actions disponibles */}
                <div className="border-t border-gray-200 dark:border-gray-700 pt-6">
                  <div className="flex justify-between">
                    <div>
                      {availableTransitions.length > 0 && !selectedInstance.completed && (
                        <div className="space-y-2">
                          <h4 className="text-md font-medium text-gray-900 dark:text-white mb-2">
                            Actions disponibles:
                          </h4>
                          <div className="flex flex-wrap gap-2">
                            {availableTransitions.map(transition => (
                              <Button
                                key={transition.event}
                                variant="outline"
                                onClick={() => {
                                  handleProcessEvent(selectedInstance.id, transition.event);
                                  setShowDetailsModal(false);
                                }}
                                disabled={actionLoading}
                              >
                                {transition.event}
                              </Button>
                            ))}
                          </div>
                        </div>
                      )}
                      
                      {selectedInstance.error && (
                        <div className="mt-4 p-3 bg-red-50 dark:bg-red-900/20 border-l-4 border-red-500 text-red-700 dark:text-red-400">
                          <div className="flex items-start">
                            <AlertCircle className="h-5 w-5 mr-2 mt-0.5" />
                            <div>
                              <p className="font-medium">Erreur dans le workflow</p>
                              <p className="text-sm mt-1">{
                                selectedInstance.context?.errorMessage || 
                                selectedInstance.errorMessage || 
                                "Une erreur est survenue lors de l'exécution du workflow."
                              }</p>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                    <div className="flex space-x-3 items-start">
                      {!selectedInstance.completed && !selectedInstance.error && (
                        <Button
                          variant="outline"
                          icon={XSquare}
                          onClick={() => {
                            handleCancelWorkflow(selectedInstance.id);
                            setShowDetailsModal(false);
                          }}
                          className="text-red-500 border-red-300 hover:bg-red-50"
                          disabled={actionLoading}
                        >
                          Annuler le workflow
                        </Button>
                      )}
                      <Button
                        variant="outline"
                        onClick={() => setShowDetailsModal(false)}
                      >
                        Fermer
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default WorkflowInstancesMonitor;